<template>
  <div style="height: inherit">
    <!-- {{ getStorageTypes }} -->
    <!-- {{ this.selectedLocation }} -->
    <!-- Warenkorb ({{ $store.getters.cartItemCount }}) -->
    <!-- {{ $store.getters.locationsEntries }} -->
    <!-- {{ $store.getters.cart }} -->
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div>
          <div class="mb-2">
            <b-list-group horizontal disabled>
              <b-list-group-item
                ><b-badge variant="primary" pill>1</b-badge> Standort
                auswählen...</b-list-group-item
              >
              <b-list-group-item
                ><b-badge variant="primary" pill>2</b-badge> Lager
                auswählen...</b-list-group-item
              >
              <b-list-group-item
                ><b-badge variant="primary" pill>3</b-badge> Anfrage
                senden!</b-list-group-item
              >
            </b-list-group>
          </div>
          <div class="ecommerce-header-items">
            <div :style="contentStyle">
              <b-button
                class="mr-2 button-hide-mobile"
                variant="outline-primary"
                @click="toggleSidebar"
              >
                <feather-icon icon="FilterIcon" size="14" />
              </b-button>

              <b-dropdown
                :disabled="selectedLocation && $store.getters.cartItemCount > 0"
                :text="
                  selectedLocation
                    ? selectedLocation.name
                    : 'Standort auswählen...'
                "
                right
                variant="primary"
              >
                <b-dropdown-item @click="updateLocation(null)">
                  Alle
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item
                  v-for="location in $store.getters.locationsEntries"
                  :key="location.name"
                  @click="updateLocation(location)"
                >
                  {{ location.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="view-options d-flex justify-content-end">
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- <portal to="content-renderer-sidebar-detached-left" v-if="isSidebarVisible">
      <ecommerce-shop-left-filter-sidebar />
    </portal> -->

    <!-- Prodcuts -->
    <section :class="itemView" :style="contentStyle">
      <b-card
        v-for="product in getStorageTypes.sort(
          (a, b) => a.salesViewOrder - b.salesViewOrder
        )"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div v-if="product.salesViewHighlight" class="highlight-banner">
          Unsere Empfehlung
        </div>
        <div v-if="product.image.length > 0" class="item-img text-center">
          <b-link
            :disabled="!selectedLocation"
            :to="{
              name: 'apps-e-commerce-product-details',
              params: { id: product.id },
            }"
          >
            <b-img
              :alt="`${product.name}`"
              fluid
              class="card-img-top"
              :src="`${baseURL}${product.image[0].url}`"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div>
              <h6 class="item-price">{{ product.price }} €</h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{
                name: 'apps-e-commerce-product-details',
                params: { id: product.id },
              }"
            >
              {{ product.name }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25">
                <!-- {{ product.brand }} -->
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            <span v-html="product.description" />
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">{{ product.price }} €</h4>
            </div>
          </div>
          <b-button
            :disabled="!selectedLocation"
            :variant="
              $store.getters.cart && $store.getters.cart.includes(product)
                ? 'success'
                : 'primary'
            "
            tag="a"
            class="btn-cart"
            @click="handleCartActionClick(product)"
          >
            <feather-icon icon="ShoppingCartIcon" class="mr-50" />
            <span>{{
              $store.getters.cart && $store.getters.cart.includes(product)
                ? 'Buchungsanfrage anzeigen'
                : 'Buchungsanfrage hinzufügen'
            }}</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left" v-if="isSidebarVisible">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BListGroup,
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import API from '@/service/api'
// import { data } from 'vue-echarts'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
} from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    API,
    BListGroup,
    BListGroupItem,
    BBadge,
    // SFC
    ShopLeftFilterSidebar,
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const { itemView, itemViewOptions, totalProducts } = useShopUi()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    watch(filters, () => {
      // This function will be called when any property of `filters` is changed
      // Put any code here that needs to run when filters are changed
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },

  data() {
    return {
      // selectedLocation: null, // This is the selected location
      baseURL: API().defaults.baseURL,
      isSidebarVisible: false,
    }
  },

  computed: {
    selectedLocation() {
      return this.$store.getters.selectedLocation
    },

    getStorageTypes() {
      let products = this.$store.getters.getStorageTypes

      // Filter by location
      if (this.selectedLocation) {
        products = products.filter(product =>
          product.storages.some(
            storage => storage.location === this.selectedLocation.id
          )
        )
      }

      // Filter by category
      if (this.filters.categories) {
        if (this.filters.categories !== 'Alle') {
          products = products.filter(
            product => product.name === this.filters.categories
          )
        }
      }

      // Filter by price range
      if (
        this.filters.priceRangeDefined &&
        this.filters.priceRangeDefined !== 'all'
      ) {
        let [lowerBound, upperBound] = this.filters.priceRangeDefined.split('-')
        lowerBound = parseInt(lowerBound, 10) // specify radix as 10
        upperBound = parseInt(upperBound, 10) // specify radix as 10
        products = products.filter(
          product => product.price >= lowerBound && product.price <= upperBound
        )
      }

      // // Filter by size range
      if (this.filters.sizeRange) {
        const [minSize, maxSize] = this.filters.sizeRange.map(parseFloat) // parse strings to floats

        products = products.filter(product =>
          product.storages.some(storage => {
            const storageSize = parseFloat(storage.storageSpace) // ensure storage size is a float
            return storageSize >= minSize && storageSize <= maxSize
          })
        )
      }

      return products
    },

    contentStyle() {
      if (this.isSidebarVisible) {
        // Sidebar is visible, apply margin
        return {
          marginLeft: 'calc(260px + 2rem)',
        }
      } else {
        // Sidebar is hidden, remove margin
        return {
          marginLeft: '0px',
        }
      }
    },
  },

  methods: {
    // existing methods
    updateLocation(location) {
      // Verwende eine Action
      this.$store.dispatch('updateSelectedLocation', location)
    },

    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
.highlight-banner {
  background-color: #ffc800; /* Bootstrap primary color */
  font-size: 1.15rem;
  // bold
  font-weight: 600;
  color: white;
  padding: 10px;
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
  .body .content-detached.content-right .content-body {
    margin-left: 0px !important;
  }
}
</style>
