var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"inherit"}},[_c('section',{attrs:{"id":"ecommerce-header"}},[_c('div',{staticClass:"row"},[_c('div',[_c('div',{staticClass:"mb-2"},[_c('b-list-group',{attrs:{"horizontal":"","disabled":""}},[_c('b-list-group-item',[_c('b-badge',{attrs:{"variant":"primary","pill":""}},[_vm._v("1")]),_vm._v(" Standort auswählen...")],1),_c('b-list-group-item',[_c('b-badge',{attrs:{"variant":"primary","pill":""}},[_vm._v("2")]),_vm._v(" Lager auswählen...")],1),_c('b-list-group-item',[_c('b-badge',{attrs:{"variant":"primary","pill":""}},[_vm._v("3")]),_vm._v(" Anfrage senden!")],1)],1)],1),_c('div',{staticClass:"ecommerce-header-items"},[_c('div',{style:(_vm.contentStyle)},[_c('b-button',{staticClass:"mr-2 button-hide-mobile",attrs:{"variant":"outline-primary"},on:{"click":_vm.toggleSidebar}},[_c('feather-icon',{attrs:{"icon":"FilterIcon","size":"14"}})],1),_c('b-dropdown',{attrs:{"disabled":_vm.selectedLocation && _vm.$store.getters.cartItemCount > 0,"text":_vm.selectedLocation
                  ? _vm.selectedLocation.name
                  : 'Standort auswählen...',"right":"","variant":"primary"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateLocation(null)}}},[_vm._v(" Alle ")]),_c('b-dropdown-divider'),_vm._l((_vm.$store.getters.locationsEntries),function(location){return _c('b-dropdown-item',{key:location.name,on:{"click":function($event){return _vm.updateLocation(location)}}},[_vm._v(" "+_vm._s(location.name)+" ")])})],2)],1),_c('div',{staticClass:"view-options d-flex justify-content-end"},[_c('b-form-radio-group',{staticClass:"ml-1 list item-view-radio-group",attrs:{"buttons":"","size":"sm","button-variant":"outline-primary"},model:{value:(_vm.itemView),callback:function ($$v) {_vm.itemView=$$v},expression:"itemView"}},_vm._l((_vm.itemViewOptions),function(option){return _c('b-form-radio',{key:option.value,attrs:{"value":option.value}},[_c('feather-icon',{attrs:{"icon":option.icon,"size":"18"}})],1)}),1)],1)])])])]),_c('div',{staticClass:"body-content-overlay"}),_c('section',{class:_vm.itemView,style:(_vm.contentStyle)},_vm._l((_vm.getStorageTypes.sort(
        function (a, b) { return a.salesViewOrder - b.salesViewOrder; }
      )),function(product){return _c('b-card',{key:product.id,staticClass:"ecommerce-card",attrs:{"no-body":""}},[(product.salesViewHighlight)?_c('div',{staticClass:"highlight-banner"},[_vm._v(" Unsere Empfehlung ")]):_vm._e(),(product.image.length > 0)?_c('div',{staticClass:"item-img text-center"},[_c('b-link',{attrs:{"disabled":!_vm.selectedLocation,"to":{
            name: 'apps-e-commerce-product-details',
            params: { id: product.id },
          }}},[_c('b-img',{staticClass:"card-img-top",attrs:{"alt":("" + (product.name)),"fluid":"","src":("" + _vm.baseURL + (product.image[0].url))}})],1)],1):_vm._e(),_c('b-card-body',[_c('div',{staticClass:"item-wrapper"},[_c('div',[_c('h6',{staticClass:"item-price"},[_vm._v(_vm._s(product.price)+" €")])])]),_c('h6',{staticClass:"item-name"},[_c('b-link',{staticClass:"text-body",attrs:{"to":{
              name: 'apps-e-commerce-product-details',
              params: { id: product.id },
            }}},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('b-card-text',{staticClass:"item-company"},[_c('b-link',{staticClass:"ml-25"})],1)],1),_c('b-card-text',{staticClass:"item-description"},[_c('span',{domProps:{"innerHTML":_vm._s(product.description)}})])],1),_c('div',{staticClass:"item-options text-center"},[_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"item-cost"},[_c('h4',{staticClass:"item-price"},[_vm._v(_vm._s(product.price)+" €")])])]),_c('b-button',{staticClass:"btn-cart",attrs:{"disabled":!_vm.selectedLocation,"variant":_vm.$store.getters.cart && _vm.$store.getters.cart.includes(product)
              ? 'success'
              : 'primary',"tag":"a"},on:{"click":function($event){return _vm.handleCartActionClick(product)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingCartIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$store.getters.cart && _vm.$store.getters.cart.includes(product) ? 'Buchungsanfrage anzeigen' : 'Buchungsanfrage hinzufügen'))])],1)],1)],1)}),1),_c('section',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalProducts,"per-page":_vm.filters.perPage,"first-number":"","align":"center","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1)],1),(_vm.isSidebarVisible)?_c('portal',{attrs:{"to":"content-renderer-sidebar-detached-left"}},[_c('shop-left-filter-sidebar',{attrs:{"filters":_vm.filters,"filter-options":_vm.filterOptions,"mq-shall-show-left-sidebar":_vm.mqShallShowLeftSidebar},on:{"update:mqShallShowLeftSidebar":function($event){_vm.mqShallShowLeftSidebar=$event},"update:mq-shall-show-left-sidebar":function($event){_vm.mqShallShowLeftSidebar=$event}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }