import store from '@/store'
import { ref } from '@vue/composition-api'

export const useShopFiltersSortingAndPagination = () => {
  let products = store.getters.getStorageTypes
  const uniqueCategories = Array.from(
    new Set(products.map(product => product.name))
  )

  const filters = ref({
    priceRangeDefined: 'all',
    sizeRange: [0, 100],
    categories: 'Alle',
    page: 1,
    perPage: 9,
  })

  const filterOptions = {
    priceRangeDefined: [
      { text: 'Alle', value: 'all' },
      { text: 'bis €100', value: '0-99' },
      { text: '€100 - €200', value: '100-199' },
      { text: '€200 - €300', value: '200-299' },
      { text: 'über €300', value: '301-1000' },
    ],
    categories: ['Alle', ...uniqueCategories],
  }

  const sortBy = ref({ text: 'Beliebt', value: 'featured' })
  const sortByOptions = [
    { text: 'Featured', value: 'featured' },
    { text: 'Lowest', value: 'price-asc' },
    { text: 'Highest', value: 'price-desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}
